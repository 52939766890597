.cp-quill-editor {
  display: flex;
  flex-direction: column-reverse;
  &--toolbar-scroll {
    & .ql-picker-options {
      max-height: 9rem;
      overflow: auto;
    }
  }
  & .ql-toolbar.ql-snow {
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    width: 450px;
    left: 50%;
    position: relative;
    transform: translate(-50%);
    z-index: 2;
    & .ql-picker-options {
      bottom: 100%;
      top: auto !important;
    }
  }
  & .ql-container {
    font-size: 1rem;
    min-height: 6rem;
    & .ql-editor {
      font-family: $font-family-base;
      & h6 {
        font-size: 1rem;
        font-weight: 500;
      }
      & h5 {
        font-size: 1.25rem;
        font-weight: 500;
      }
      & h4 {
        font-size: 1.5rem;
        font-weight: 500;
      }
      & h3 {
        font-size: 1.75rem;
        font-weight: 500;
      }
      & h2 {
        font-size: 2rem;
        font-weight: 500;
      }
      & h1 {
        font-size: 2.5rem;
        font-weight: 500;
      }
    }
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item {
    font-family: $font-family-base;
    &[data-value="1"]::before {
      font-size: 2.5rem;
      font-weight: 500;
    }
    &[data-value="2"]::before {
      font-size: 2rem;
      font-weight: 500;
    }
    &[data-value="3"]::before {
      font-size: 1.75rem;
      font-weight: 500;
    }
    &[data-value="4"]::before {
      font-size: 1.5rem;
      font-weight: 500;
    }
    &[data-value="5"]::before {
      font-size: 1.25rem;
      font-weight: 500;
    }
    &[data-value="6"]::before {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  & .ql-container.ql-snow {
    border: none;
    overflow: auto;
  }

  & .ql-toolbar {
    & .ql-stroke {
      fill: none;
      stroke: map-get($map: $text, $key: 300);
    }

    & .ql-fill {
      fill: map-get($map: $text, $key: 300);
      stroke: none;
    }

    & .ql-picker {
      color: map-get($map: $text, $key: 300);
    }
  }
}
.text-editor-content {
  padding: 12px 15px;
  display: block;
  min-height: 45px;
  & p {
    margin: 0rem;
  }
}

.cp-quill-notelet .ql-editor {
  min-height: 10rem;
}
